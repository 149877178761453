<template>
  <div class="signatureList-page">
    <a-spin :spinning="loading" style="height: 100%">
      <div class="list">
        <div
          class="list-item"
          hoverable
          :class="{ active: checkedId === item.id }"
          v-for="item in list"
          :key="item.id"
          :id="item.id"
          @click="selectItem(item)"
          draggable="true"
          @dragstart="DragStartFn(item, $event)"
          @dragover.prevent
        >
          <div class="delete-icon">
            <a-tooltip placement="bottom">
                <template slot="title">
                  <span>删除</span>
                </template>
                <a-icon class="delete" @click="delFn(item.id)" type="delete" />
              </a-tooltip>
          </div>
          <div class="img-box">
            <img :id="'img'+item.id" :src="item.materialParams" alt="" />
          </div>
        </div>
        <!-- 暂无数据 -->
        <div class="list-item-empty" v-if="!list.length">
          <a-list :data-source="[]" />
        </div>
      </div>
    </a-spin>
    <div class="empty">
      <!-- 占位 -->
    </div>
    <div class="add-btn" @click="addSignature">
      <a-icon class="add-btn-icon" type="plus" />
      <span>添加签名</span>
    </div>
  </div>
</template>

<script>
import { Switch } from "ant-design-vue";
export default {
  name: "signatureList",
  components: {
    "a-switch": Switch,
  },
  data() {
    return {
      loading: false,
      imgSrc: "",
      checkedId: "",
      list: [],
    };
  },
  props: {
    // 父组件传递过来的参数
    //   visibleWatermark: {
    //     type: Boolean,
    //     default: false,
    //   },
  },

  watch: {
    //   visibleWatermark: {
    //     handler(newVal) {
    //       this.visible = newVal;
    //     },
    //     immediate: true,
    //   },
  },

  computed: {},
  created() {
    this.getList();
  },

  mounted() {},

  methods: {

    DragStartFn() {
      this.$emit('DragStartFn')
    },

    // 删除签名
    async delFn(id) {
      console.log("id", id);
      let params = {
        id,
        materialType: "SIGNATURE",
      };
      const res = await this.$apis.removeWaterListHttp(params);
      if (res.code == 200) {
        this.$message.success("删除成功");
        // 更新签名列表
        this.getList();
      }
    },


    // 选择签名
    selectItem(item) {
      console.log("选择了某个签名");
      this.checkedId = item.id;
      // this.$emit("selectSignature", item.materialParams);

      this.$emit("addSignaToPdf", item.materialParams);
    },

    async getList() {
      this.loading = true;
      let params = {
        materialType: "SIGNATURE",
      };
      const res = await this.$apis.getPdfmaterialListkHttp(params);
      console.log("签名res", res);
      this.list = res?.data;
      this.loading = false;
    },
    addSignature() {
      this.$emit("openAddSignature",this.list.length);
    },
  },
};
</script>

<style lang="less" scoped>
.signatureList-page {
  width: 230px;
  padding-top: 10px !important;
  .empty {
    width: 90%;
    height: 1px;
    background-color: #ccc;
    margin: 0 auto;
    margin-top: 10px;
  }
  .add-btn {
    height: 30px;
    padding-left: 12px;
    color: #485269;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      // color: #4731ff;
      background-color: #e8ebef;
      // border: 1px solid #4731ff;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
    .add-btn-icon {
      margin-right: 5px;
    }
  }
  .list {
    box-sizing: border-box !important;
    max-height: 350px;
    overflow-y: auto;
    box-sizing: border-box !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
    display: flex;
    flex-direction: column; /* 保持为列方向 */
    /* 移除 align-items: center; */
    .list-item-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      width: 200px;
      height: 100px !important;
      user-select: none;
    }
    .list-item {
      background-color: #e3e3e3;
      box-sizing: border-box !important;
      margin-top: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 200px;
      position: relative;
      border: 1px solid #e3e3e3 !important;
      padding: 2px;
      &:hover {
        border: 1px solid #ee9696 !important;
        border-radius: 5px !important;
        .delete-icon {
          display: block; /* 鼠标悬停时显示删除图标 */
        }
      }
      .delete-icon {
        display: none; /* 默认隐藏删除图标 */
        position: absolute;
        top: 10px;
        right: 10px;
        .delete {
          font-size: 14px;
        }
      }

      .img-box {
        width: 100%;
        height: 100px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 98%;
          height: 98%;
          object-fit: contain;
        }
      }
    }
    .active {
      border: 1px solid #ee9696 !important;
      border-radius: 5px !important;
    }
  }
}
</style>


<style>

.ant-empty-normal {
   margin: 0 !important;
}
.ant-list-empty-text {
  margin-right: 8px !important;
  height: 98px !important;
}
</style>