<template>
  <div>
    <!-- :mask="false" -->
    <a-modal
      v-model="visible"
      :width="800"
      :title="title"
      @ok="handleOk"
      :maskClosable="false"
      :closable="false"
      :footer="null"
    >
      <div class="addWatermarkModal-content">
        <div class="left">
          <!-- 水印文本 -->
          <div class="text-input">
            <span>水印文本</span>
            <a-input
              :maxLength="30"
              v-model="form.watermarkText"
              style="margin-top: 10px"
              placeholder="请输入水印文本"
            />
            <div style="display: flex; justify-content: flex-end">
              <span>{{ form.watermarkText.length }}/30</span>
            </div>
          </div>
          <!-- 字体大小 -->
          <div class="text-font-size" style="margin-top: 8px">
            <span>字体大小</span>
            <a-row style="display: flex; margin-top: 5px; align-items: center">
              <a-col :span="20">
                <a-slider
                  style="padding: 0 !important; margin: 0 !important"
                  v-model="form.fontSize"
                  :min="10"
                  :max="150"
                />
              </a-col>
              <a-col :span="4">
                <div style="display: flex; justify-content: flex-end">
                  <span style="font-size: 16px; font-weight: 600">{{
                    form.fontSize
                  }}</span>
                </div>
              </a-col>
            </a-row>
          </div>

          <!-- 不透明度 -->
          <div class="opacity" style="margin-top: 8px">
            <span>不透明度</span>
            <a-row style="display: flex; margin-top: 5px; align-items: center">
              <a-col :span="20">
                <a-slider
                  :tip-formatter="formatter"
                  style="padding: 0 !important; margin: 0 !important"
                  v-model="form.opacity"
                  :min="0"
                  :max="100"
                />
              </a-col>
              <a-col :span="4">
                <div style="display: flex; justify-content: flex-end">
                  <span style="font-size: 16px; font-weight: 600"
                    >{{ form.opacity }}%</span
                  >
                </div>
              </a-col>
            </a-row>
          </div>

          <!-- 旋转角度 -->
          <div class="rotate" style="margin-top: 5px">
            <div class="rotate-text">旋转角度:</div>
            <a-select
              class="rotate-select"
              style="margin-top: 5px; width: 100%"
              v-model="form.rotation"
            >
              <a-select-option
                v-for="item in rotateList"
                :key="item.value"
                :value="item.value"
                >{{ item.text }}</a-select-option
              >
            </a-select>
          </div>

          <!-- 水平距离 -->
          <div class="level" style="margin-top: 12px">
            <span>水平距离</span>
            <a-row style="display: flex; align-items: center">
              <a-col :span="20">
                <a-slider
                  style="padding: 0 !important; margin: 0 !important"
                  v-model="form.widthSpacer"
                  :min="10"
                  :max="150"
                />
              </a-col>
              <a-col :span="4">
                <div style="display: flex; justify-content: flex-end">
                  <span style="font-size: 16px; font-weight: 600">{{
                    form.widthSpacer
                  }}</span>
                </div>
              </a-col>
            </a-row>
          </div>

          <!-- 垂直距离 vertical-->
          <div class="vertical" style="margin-top: 10px">
            <span>垂直距离</span>
            <a-row style="display: flex; margin-top: 5px; align-items: center">
              <a-col :span="20">
                <a-slider
                  style="padding: 0 !important; margin: 0 !important"
                  v-model="form.heightSpacer"
                  :min="10"
                  :max="150"
                />
              </a-col>
              <a-col :span="4">
                <div style="display: flex; justify-content: flex-end">
                  <span style="font-size: 16px; font-weight: 600">{{
                    form.heightSpacer
                  }}</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="center"></div>
        <div class="right" id="watermark-right"></div>
      </div>

      <div class="addWatermarkModal-footer">
        <div class="left">
          <a-checkbox
            :disabled="watermarkListLength >= 10"
            v-model="saveModuleFlag"
          ></a-checkbox>
          <span
            @click="
              watermarkListLength <= 9 ? (saveModuleFlag = !saveModuleFlag) : ''
            "
            style="
              font-size: 14px;
              color: #666;
              cursor: pointer;
              margin-left: 10px;
            "
            >保存到自定义水印，可以快速调用</span
          >
        </div>
        <div class="right">
          <a-button style="margin-right: 15px" @click="close">取消</a-button>
          <a-button type="primary" :disabled="!form.watermarkText.length" @click="confirmAddWatermark">确定</a-button>
        </div>
        <div class="watermarkListLengthMax" v-if="watermarkListLength >= 10">
          已达到模板最大数量限制
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { generateWatermark } from "@/utils";
import { title } from "process";
export default {
  name: "AddWatermarkModal",
  components: {},
  data() {
    return {
      title: "添加水印",
      saveModuleFlag: false,
      form: {
        watermarkText: "示例文本", //	水印文本
        fontSize: 100, //	字体大小
        opacity: 10, //	透明度
        rotation: 45, // 旋转角度
        heightSpacer: 100, //	垂直间距
        widthSpacer: 100, //	水平间距
      },

      visible: false,
      rotateList: [
        {
          value: 45,
          text: "45°",
        },
        {
          value: 0,
          text: "0°",
        },
        {
          value: -45,
          text: "-45°",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.editData && this.editData.fontSize) {
        this.saveModuleFlag = true;
        this.form = {
          ...this.editData,
          opacity: this.editData.opacity * 100,
          editId: this.editData.id,
        };
        this.title = "编辑水印";
      } else {
        this.title = "添加水印";
        this.form = {
          watermarkText: "示例文本", //	水印文本
          fontSize: 100, //	字体大小
          opacity: 10, //	透明度
          rotation: 45, // 旋转角度
          heightSpacer: 100, //	垂直间距
          widthSpacer: 100, //	水平间距
        };
      }
      setTimeout(() => {
        this.getWater(this.form);
      }, 500);
    });
  },
  props: {
    // 父组件传递过来的参数
    visibleWatermark: {
      type: Boolean,
      default: false,
    },
    watermarkListLength: {
      type: Number,
      default: 0,
    },
    // 编辑水印模板数据源
    editData: {
      type: Object,
      default: {},
    },
  },

  watch: {
    visibleWatermark: {
      handler(newVal) {
        this.visible = newVal;
      },
      immediate: true,
    },
    form: {
      handler(newVal) {
        this.getWater(newVal);
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {},

  methods: {
    getWater(form) {
      // let water = {
      //   watermarkText: form.watermarkText, //	水印文本
      //   fontSize: form.fontSize, //	字体大小
      //   opacity: form.opacity, //	透明度
      //   rotate: form.rotation, // 旋转角度
      //   heightSpacer: form.heightSpacer, //	垂直间距
      //   widthSpacer: form.widthSpacer, //	水平间距
      // };
      let params = {
        targetDomId: "watermark-right",
        watermarkText: form.watermarkText,
        fontSize: form.fontSize * 0.5,
        opacity: form.opacity / 100,
        rotation: form.rotation,
        heightSpacer: form.heightSpacer * 0.5,
        widthSpacer: form.widthSpacer * 0.5,
      };
      console.log("水印参数", params);

      generateWatermark(params);
    },
    formatter(value) {
      return `${value}%`;
    },
    confirmAddWatermark() {
      console.log("this.form", this.form);
      if (this.editData && this.editData.fontSize) {
        this.$emit(
          "confirmAddWatermark",
          this.form,
          this.saveModuleFlag,
          "edit"
        );
      } else {
        this.$emit("confirmAddWatermark", this.form, this.saveModuleFlag);
      }
    },
    close() {
      this.$emit("closeVisibleWatermark", false);
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.addWatermarkModal-content {
  display: flex;
  user-select: none;
  height: 380px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  .left {
    flex: 1;
    height: 100%;
  }
  .center {
    height: 100%;
    width: 20px;
  }
  .right {
    flex: 1;
    height: 100%;
    // background-color: #ccc;
    border: 1px solid #ccc;
  }
}
.addWatermarkModal-footer {
  display: flex;
  justify-content: space-between;

  .watermarkListLengthMax {
    font-size: 12px;
    position: absolute;
    left: 50px;
    bottom: 10px;
    color: red;
  }

  .left {
    display: flex;
    align-items: center;
    user-select: none;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// .ant-modal-wrap {
//   z-index: 3000 !important;
// }
// .ant-modal-mask {
//   z-index: 3000 !important;
// }

.ant-slider-track {
  background-color: #3271ff !important;
}
</style>
