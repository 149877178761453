<template>
  <div class="watermarkList-page">
    <div style="width: 100%; height: 1px">
      <!-- 占位 -->
    </div>
    <div class="content-page">
      <div class="header">
        <div class="left">
          <span>自定义水印列表</span>
          <span style="margin-left: 10px"
            >({{ waterList.length }}/{{ num2 }})</span
          >
        </div>
        <!-- <div class="right">
          <a-icon class="delete" @click="delFn" type="delete" />
        </div> -->
      </div>
      <a-spin :spinning="loading" style="height: 100%">
        <div class="list">
          <a-card class="item add-btn" @click="addWaterDialog">
            <!-- <a-icon type="plus" style="font-size: 18px" /> -->
            <span class="btn-txt">添加水印</span>
          </a-card>
          <a-card
            class="item"
            hoverable
            :class="{ active: checkedId === item.id }"
            v-for="item in waterList"
            :key="item.id"
            @click="selectWatermarkTemplate(item)"
          >
            <div class="edit-icon">
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>编辑</span>
                </template>
                <a-icon
                  class="edit"
                  @click="editFn(item, $event)"
                  type="edit"
                />
              </a-tooltip>
            </div>

            <div class="delete-icon">
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>删除</span>
                </template>
                <a-icon
                  class="delete"
                  @click="deleteFn(item, $event)"
                  type="delete"
                />
              </a-tooltip>
            </div>

            <span
              :class="'watermark-item' + item.id"
              v-for="i in 100"
              :key="item.id"
            >
              {{ item.watermarkText }}
            </span>
          </a-card>
        </div>
      </a-spin>
      <div class="footer" style="margin-top: 20px; margin-bottom: 5px">
        <!-- <span style="margin-right: 10px">开启水印</span>
        <a-switch size="small" v-model="switchFlag" /> -->
        <div class="deleteWater" @click="deleteWater">
          <a-icon
            class="delete-water"
            @click="deleteFn(item, $event)"
            type="delete"
          />
          <span>删除水印</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateWatermark } from "@/utils";
import { Switch } from "ant-design-vue";
export default {
  name: "watermarkList",
  components: {
    "a-switch": Switch,
  },
  data() {
    return {
      // switchFlag: true,
      loading: false,
      num1: 2,
      num2: 10,
      checkedId: "",
      waterList: [],
    };
  },
  props: {
    // 父组件传递过来的参数
    //   visibleWatermark: {
    //     type: Boolean,
    //     default: false,
    //   },
  },

  watch: {
    //   visibleWatermark: {
    //     handler(newVal) {
    //       this.visible = newVal;
    //     },
    //     immediate: true,
    //   },
  },

  computed: {},
  created() {
    this.getWaterList();
  },

  mounted() {},
  methods: {
    // 删除页面水印

    
    deleteWater() {
      this.$emit("deleteWater");
    },

    // 删除水印模板
    async deleteFn(item, e) {
      e.stopPropagation();
      console.log("item", item);
      // this.$emit("addWaterDialog", this.waterList.length, item);
      let params = {
        id: item.id,
        materialType: "WATERMARK",
      };
      const res = await this.$apis.removeWaterListHttp(params);
      if (res.code == 200) {
        this.$message.success("删除成功");
        this.getWaterList();
      }
    },

    // 编辑水印模板
    editFn(item, e) {
      e.stopPropagation();
      console.log("item", item);
      this.$emit("addWaterDialog", this.waterList.length, item);
    },
    // 选择水印模板
    selectWatermarkTemplate(item) {
      // this.checkedId = item.id;
      // if (!this.switchFlag) {
      //   return;
      // }
      let params = {
        ...item,
        opacity: item.opacity * 100,
      };
      this.$emit("confirmAddWatermark", params, false);
    },

    async getWaterList() {
      this.loading = true;
      let params = {
        materialType: "WATERMARK",
      };
      const res = await this.$apis.getPdfmaterialListkHttp(params);
      this.loading = false;

      this.waterList = res.data.map((item) => {
        let targetObj = JSON.parse(item.materialParams);
        let params = {
          targetDomId: item.id,
          watermarkText: targetObj.watermarkText,
          fontSize: targetObj.fontSize,
          opacity: targetObj.opacity,
          rotate: targetObj.rotation,
          widthSpacer: targetObj.widthSpacer,
          heightSpacer: targetObj.heightSpacer,
        };

        this.$nextTick(() => {
          setTimeout(() => {
            let targetDomList = document.querySelectorAll(
              ".watermark-item" + item.id
            ); // 使用类名选择器

            targetDomList.forEach(function (targetDom) {
              // 确保 targetObj 的属性都是有效的 CSS 值
              targetDom.style.display = "inline-block";
              targetDom.style.marginTop = targetObj.heightSpacer * 0.25 + "px"; // 假设 heightSpacer 是一个数字，需要加上单位
              targetDom.style.marginRight = targetObj.widthSpacer * 0.15 + "px"; // 同样，需要加上单位
              targetDom.style.transform = `rotate(${-targetObj.rotation}deg)`;
              targetDom.style.opacity = targetObj.opacity + 0.05;
              targetDom.style.fontSize = targetObj.fontSize * 0.15 + "px"; // 如果 fontSize 是 rem 或 em，则不需要 'px'
            });
          }, 0);
        });

        return {
          id: item.id,
          ...targetObj,
        };
      });

      console.log("this.waterList", this.waterList);
    },
    addWaterDialog() {
      this.$emit("addWaterDialog", this.waterList.length);
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
  },
};
</script>

<style lang="less" scoped>
.watermarkList-page {
  //   padding: 12px;
  background-color: transparent !important;
  //   border: 1px solid #ccc;
  box-sizing: border-box;
  width: 450px;
  padding: 0 !important;
  .header {
    padding: 20px;
    padding-bottom: 0px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    .right {
      .delete {
        font-size: 16px;
        cursor: pointer;
        &:hover {
          font-size: 16px;
          font-weight: 700;
          color: red !important;
        }
      }
    }
  }
  .list {
    padding: 20px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 10px !important;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .add-btn {
      padding: 0 !important;
      border: 1px solid #4731ff;
      color: #4731ff;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      .btn-txt {
      }
      &:hover {
        border-radius: 5px;
        color: #4731ff;
        font-size: 16px;
        border: 1px solid #4731ff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      }
    }

    .active {
      // border: 1px solid #ee9696 !important;
      border-radius: 5px !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5) !important;
    }
    .item {
      overflow: hidden;
      cursor: pointer;
      margin-top: 12px;
      margin-right: 12px;
      height: 150px;
      flex: 0 0 calc(33.333% - 12px); /* 减去两边各10px的间隔 */
      box-sizing: border-box; /* 确保padding和border不会增加元素的总宽度 */
      // display: flex;
      // justify-content: center;
      // align-items: center;
      &:hover {
        border: 1px solid #ee9696 !important;
        border-radius: 5px !important;
        .edit-icon {
          display: block; /* 鼠标悬停时显示删除图标 */
        }
        .delete-icon {
          display: block; /* 鼠标悬停时显示删除图标 */
        }
      }
      .edit-icon {
        display: none; /* 默认隐藏删除图标 */
        position: absolute;
        top: 10px;
        right: 10px;
        .edit {
          font-size: 14px;
        }
      }
      .delete-icon {
        z-index: 9999;
        display: none; /* 默认隐藏删除图标 */
        position: absolute;
        top: 10px;
        right: 30px;
        .delete {
          font-size: 14px;
        }
      }
    }
  }
  .footer {
    // padding: 20px;
    padding-top: 0 !important;
    padding-bottom: 20px;
    .deleteWater {
      user-select: none;
      cursor: pointer;
      padding-left: 20px;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: rgba(232, 235, 239, 0.5);
        // color: red
      }
      .delete-water {
        margin-right: 12px;
      }
    }
  }
}
</style>

<style>
.ant-popover-inner-content {
  padding: 0 !important;
}
.ant-card-body {
  padding: 0 !important;
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
  /* flex-direction: column !important; */
  align-items: center !important;
}
</style>
