<template>
  <div class="addSignatureModal-page">
    <a-modal
      v-model="visible"
      :width="600"
      title="PDF签名"
      class="addSignatureModal-modal"
      @ok="handleOk"
      :maskClosable="false"
      :closable="false"
      :footer="null"
    >
      <div class="content">
        <div class="btn-tab">
          <div
            class="item"
            :class="activeMenu == 'write' ? 'itemActive' : ''"
            @click="changeMenu('write')"
          >
            手写
          </div>
          <div
            class="item"
            :class="activeMenu == 'input' ? 'itemActive' : ''"
            @click="changeMenu('input')"
          >
            输入
          </div>
          <div
            class="item"
            :class="activeMenu == 'image' ? 'itemActive' : ''"
            @click="changeMenu('image')"
          >
            图片
          </div>

          <div class="inputFontFamily" v-if="activeMenu == 'input'">
            <!--  -->
            <a-select class="rotate-select" v-model="inputFontFamilyVal">
              <a-select-option
                v-for="item in fontList"
                :key="item.value"
                :value="item.value"
                >{{ item.text }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="bg">
          <!-- 手写画板模块 -->
          <vue-esign
            v-if="activeMenu == 'write'"
            ref="esign"
            style="border: 1px dashed #c2c1c1"
            :isCrop="isCrop"
            :lineWidth="lineWidth"
            :lineColor="lineColor"
            :bgColor.sync="bgColor"
          />
          <!-- 输入文字模块 -->
          <div class="bg-item" v-if="activeMenu == 'input'">
            <a-input
              :maxLength="20"
              :class="inputFontFamilyVal"
              ref="inputRef"
              class="inputVal"
              autosize
              v-model="inputVal"
              @input="adjustFontSize"
            ></a-input>
          </div>

          <!-- 插入图片模块 -->
          <div class="bg-item imageModal" v-if="activeMenu == 'image'">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action=""
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                class="nature-image"
                :src="imageUrl"
                alt=""
              />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
        </div>
        <!-- 清空画布 -->

        <span class="clear-esign-left" v-if="activeMenu == 'input'">
          {{ inputVal.length }}/20
        </span>

        <div class="clear-esign" @click="handleReset">
          <a-icon class="clear-esign-icon" type="delete" />
          <span class="clear-esign-text">清除</span>
        </div>
      </div>

      <div class="footer">
        <div class="left">
          <a-checkbox
            :disabled="signatureListLength >= 10"
            v-model="saveModuleFlag"
          ></a-checkbox>
          <span
            @click="
              signatureListLength <= 9 ? (saveModuleFlag = !saveModuleFlag) : ''
            "
            style="
              font-size: 14px;
              color: #999;
              cursor: pointer;
              margin-left: 10px;
            "
            >保存到自定义，可以快速调用</span
          >
        </div>
        <div class="right">
          <a-button style="margin-right: 15px" @click="close">取消</a-button>
          <a-button
            type="primary"
            :disabled="getDisabled()"
            @click="confirmAddSignature"
            >确定</a-button
          >
        </div>

        <div class="signatureListLengthMax" v-if="signatureListLength >= 10">
          已达到模板最大数量限制
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import vueEsign from "vue-esign";
import { Switch } from "ant-design-vue";
export default {
  name: "signatureList",
  components: {
    "a-switch": Switch,
    vueEsign,
  },
  data() {
    return {
      baseFontSize: 80, // 基础字号
      minFontSize: 16, // 最小字号
      // 根据实际情况设置步长，这里假设每次输入或删除一个字符字号变化2px
      fontSizeStep: 0, // 固定值后期随着文字输入的增大，效果不好
      iptFontSize: 80,
      inputFontFamilyVal: "FangSong",
      fontList: [
        {
          text: "仿宋",
          value: "FangSong",
        },
        {
          text: "宋体",
          value: "SimSun",
        },
        {
          text: "黑体",
          value: "SimHei",
        },
        {
          text: "楷体",
          value: "KaiTi",
        },
      ],
      imageUrl: "",
      inputVal: "",
      base64ImageRes: "",
      saveModuleFlag: false,
      activeMenu: "write",
      signatureImage: false,
      lineWidth: 4,
      lineColor: "#000000",
      bgColor: "",
      isCrop: false,
      visible: true,
    };
  },
  props: {
    // 父组件传递过来的参数
    visibleAddSignatureModal: {
      type: Boolean,
      default: false,
    },
    signatureListLength: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    activeMenu: {
      handler(newVal) {
        if (newVal == "input") {
          console.log("切换到输入模式");
          this.$nextTick(() => {
            this.$refs.inputRef && this.$refs.inputRef.focus();
            this.adjustFontSize();
          });
        }
      },
      immediate: true,
    },
    visibleAddSignatureModal: {
      handler(newVal) {
        this.visible = true;
        console.log("弹窗打开", newVal);
      },
      immediate: true,
    },
  },

  computed: {},
  created() {},

  mounted() {
    this.base64ImageRes = "";
    this.inputVal = "";
  },
  methods: {
    getDisabled() {
      if (this.activeMenu == "input") {
        console.log("sssss", this.inputVal.length);
        if (this.inputVal.length) {
          return false;
        } else {
          return true;
        }
      } else if (this.activeMenu == "image") {
        if (this.imageUrl.length) {
          return false;
        } else {
          return true;
        }
      } else if (this.activeMenu == "write") {
        return false;
      }
    },
    adjustFontSize() {
      // 根据输入长度动态计算字号
      const length = this.inputVal.length;
      this.iptFontSize = this.baseFontSize;
      // 假设我们想要根据输入长度来线性减小字号
      // 这里我们简单地使用了一个线性公式，但您可以根据需要调整
      if (length > 0) {
        let fontSizeStep;
        // 根据输入长度动态调整步长
        // 这里假设当长度小于等于15时，步长为3；之后逐渐减小到1.5
        if (length <= 15) {
          fontSizeStep = 3;
        } else {
          // 可以通过一个函数来平滑地减小步长，这里使用了一个简单的线性插值
          // 假设步长在15到30之间线性减小到1.5
          fontSizeStep = 3 - ((length - 15) * (3 - 2.1)) / 15;
          // 确保步长不小于1.5
          fontSizeStep = Math.max(fontSizeStep, 2.1);
        }

        this.iptFontSize = Math.max(
          this.minFontSize,
          this.baseFontSize - length * fontSizeStep
        );
      } else {
        // 如果没有输入内容，则恢复到基础字号
        this.iptFontSize = this.baseFontSize;
      }
      console.log("字体基础大小", this.iptFontSize);
      // 使用 Vue 的 $refs 来直接访问 DOM 元素并修改其样式

      this.$nextTick(() => {
        if (this.$refs.inputRef) {
          console.log("dom元素", this.$refs.inputRef);
          this.$refs.inputRef.$el.style.fontSize = `${this.iptFontSize}px`;
        } else {
          console.log("输入框未找到");
        }
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      console.log("测试一下", file);
      getBase64(file, (imageUrl) => {
        this.imageUrl = imageUrl;
      });

      return;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },

    base64toFile(base64Data, filename) {
      var arr = base64Data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    // 将base64签名图片插入到pdf文件上
    setBase64ImageToPdf() {
      // 开启图片批注模式
      document.querySelector(
        "#pdf-iframe"
      ).contentWindow.PDFViewerApplication.pdfViewer.annotationEditorMode = {
        mode: 13,
      };
      setTimeout(() => {
        document
          .querySelector("#pdf-iframe")
          .contentWindow.PDFViewerApplication.pdfViewer._layerProperties.annotationEditorUIManager.currentLayer.createAndAddNewEditor(
            {
              offsetX: 400,
              offsetY: 300,
            },
            true,
            {
              bitmapFile: this.base64toFile(this.base64ImageRes),
            }
          );
      }, 10);
    },

    // 手写签名模式下的获取生成base64图片方法
    writeModeGetBase64ImageFn() {
      this.$refs.esign
        .generate({ format: "image/png", width: 200 })
        .then(async (res) => {
          console.log("svg字符串", res);
          this.base64ImageRes = res;
          this.saveModule();
          this.setBase64ImageToPdf();
          this.$emit("closeVisibleAddSignatureModal", false);
        })
        .catch((err) => {
          console.log("svg字符串--err", err);
          this.$message.error("请先书写签名内容");
        });
    },
    // 输入文字模式下的获取生成base64图片方法
    imputModeGetBase64ImageFn() {
      // 获取文本宽度
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      // const font = `80px ${this.inputFontFamilyVal}`;
      const font = `${this.iptFontSize}px ${this.inputFontFamilyVal}`;
      tempCtx.font = font;
      const textWidth = tempCtx.measureText(this.inputVal).width;
      tempCanvas.remove(); // 清理临时Canvas元素

      // 设置SVG宽度和高度
      const width = Math.ceil(textWidth) + 10; // 添加一些额外宽度作为缓冲区
      const height = 100;

      // 创建SVG元素
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      svg.setAttribute("width", `${width}px`);
      svg.setAttribute("height", `${height}px`);

      // 创建文本元素
      const text = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      text.setAttribute("x", width / 2);
      text.setAttribute("y", height / 2 + 12);
      text.setAttribute("font-family", this.inputFontFamilyVal);
      text.setAttribute("font-size", this.iptFontSize + "px");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("dominant-baseline", "middle");
      text.textContent = this.inputVal;

      // 添加文本到SVG
      svg.appendChild(text);

      // 将SVG转换为XML字符串
      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svg);

      // 创建Blob对象
      const blob = new Blob([svgString], { type: "image/svg+xml" });

      // 生成File对象
      // const svgFile = new File([blob], "text.svg", { type: "image/svg+xml" });

      // 创建一个 FileReader 实例
      const reader = new FileReader();
      // 设置 FileReader 的 onload 事件处理器
      let _that = this;
      reader.onload = function (e) {
        // e.target.result 就是 Data URL，其中包含 Base64 编码的字符串
        _that.base64ImageRes = e.target.result;
        console.log("base64ImageRes", _that.base64ImageRes); // 输出 Data URL
        _that.saveModule();
        _that.setBase64ImageToPdf();
      };

      // 使用 FileReader 读取 Blob 对象的内容
      reader.readAsDataURL(blob);

      // 使用svgFile进行进一步操作，比如上传或保存
      // 例如：saveSVG(svgFile);

      // 完成后记得清理创建的临时元素和上下文
      // 清理实际Canvas元素
      // canvas.remove();

      // 使用svgFile进行进一步操作，比如上传或保存
      // 例如：saveSVG(svgFile);

      // 完成后记得清理创建的临时元素和上下文
      // 清理临时Canvas元素
      // tempCanvas.remove();

      // 清理实际Canvas元素
      // canvas.remove();

      // console.log("生成的输入文字水印图片是", base64);
      // this.setBase64ImageToPdf();
    },
    // 插入图片模式下的获取生成base64图片方法
    imageModeGetBase64ImageFn() {
      this.base64ImageRes = this.imageUrl;
      console.log(
        "插入图片模式下的获取生成base64图片方法base64ImageRes",
        this.base64ImageRes
      );
      this.saveModule();
      this.setBase64ImageToPdf();
    },

    // 切换菜单
    changeMenu(val) {
      this.activeMenu = val;
    },

    //清空画板..
    handleReset() {
      switch (this.activeMenu) {
        case "write":
          this.$refs.esign.reset();
          break;
        case "input":
          this.inputVal = "";
          break;
        case "image":
          this.imageUrl = "";
          break;
      }
      this.base64ImageRes = "";
    },
    //生成签名图片..
    handleGenerate(saveFlag) {},

    async confirmAddSignature() {
      switch (this.activeMenu) {
        case "write":
          this.writeModeGetBase64ImageFn();
          break;
        case "input":
          this.imputModeGetBase64ImageFn();
          this.$emit("closeVisibleAddSignatureModal", false);
          break;
        case "image":
          this.imageModeGetBase64ImageFn();
          this.$emit("closeVisibleAddSignatureModal", false);
          break;
      }
    },

    // 传递给后端保存签名模板
    async saveModule() {
      // 如果要保存成模板，则调用接口
      if (this.saveModuleFlag) {
        let params = {
          materialType: "SIGNATURE",
          materialParams: this.base64ImageRes,
        };
        try {
          const result = await this.$apis.addSignatureHttp(params);
          if (result.code == 200) {
            this.$message.success("保存成功");
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    },

    close() {
      this.$emit("closeVisibleAddSignatureModal", false);
    },
    handleOk(e) {
      console.log("e", e);
      // this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.addSignatureModal-page {
  user-select: none;
  width: 230px;
  padding: 20px !important;
  z-index: 2001 !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  .signatureListLengthMax {
    font-size: 12px;
    position: absolute;
    left: 50px;
    bottom: 10px;
    color: red;
    // color: #999;
  }
  .left {
    display: flex;
    align-items: center;
    user-select: none;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.content {
  user-select: none;
  .inputFontFamily {
    margin-right: 5px;
    margin-left: auto;
  }
  .clear-esign-left {
    position: absolute;
    bottom: 90px;
    right: 108px;
    font-size: 12px;
    color: #000;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear-esign {
    position: absolute;
    bottom: 90px;
    right: 40px;
    width: 60px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    .clear-esign-icon {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .btn-tab {
    display: flex;
    margin-bottom: 12px;
    .item {
      cursor: pointer;
      width: 60px;
      height: 28px;
      margin-right: 5px;
      border-radius: 14px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .itemActive {
      background-color: #417ff9;
      color: #fff !important;
    }
  }
  .bg {
    width: 100%;
    // height: 280px;
    // background-color: #b2aaaa;
    margin-bottom: 20px;
    .imageModal {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bg-item {
      width: 552px;
      height: 207px;
      border: 1px dashed #c2c1c1;
      // 图片模式下的图片
      .nature-image {
        max-width: 280px !important;
        max-height: 190px !important;
        object-fit: contain !important;
      }
    }
    .inputVal {
      height: 100%;
      // font-size: 80px;
      display: flex;
      justify-content: center;
    }
    .FangSong {
      font-family: FangSong;
    }
    .SimSun {
      font-family: SimSun;
    }
    .SimHei {
      font-family: SimHei;
    }
    .KaiTi {
      font-family: KaiTi;
    }
  }
}
</style>

<style>
.ant-upload {
  min-width: 400px !important;
  min-height: 130px !important;
  max-width: 552px !important;
  max-height: 200px !important;
  margin-top: 8px;
}

.ant-upload-picture-card-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
