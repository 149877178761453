<template>
  <div class="pdf-edit-page">
    <div class="custom-menu-btn">
      <!-- 图片 -->
      <div class="item-box" @click="addImage">
        <div class="item-box-pop">
          <img
            style="width: 20px; height: 20px"
            src="@/assets/svg/documentTool/actionMenu/pdf_edit_addImage.svg"
            alt=""
          />
          <span>图片</span>
        </div>
      </div>

      <!-- 添加水印 -->
      <div class="item-box">
        <a-popover placement="bottom" class="item-box-pop" v-model="visible">
          <template slot="content">
            <!-- 水印列表子组件 -->
            <watermarkList
              class="showWatermarkList"
              v-if="visible"
              @addWaterDialog="openAddWatermark"
              @deleteWater="deleteWater"
              @confirmAddWatermark="confirmAddWatermark"
            />
          </template>
          <!-- <a-button class="addWater"> 添加水印 </a-button> -->
          <img
            style="width: 18px; height: 18px"
            src="@/assets/svg/documentTool/actionMenu/pdf_edit_watermark.svg"
            alt=""
          />
          <span>水印</span>
        </a-popover>
      </div>

      <!-- PDF签名 -->
      <div class="item-box">
        <a-popover v-model="signatureVisible" class="item-box-pop">
          <template slot="content">
            <!-- 签名列表子组件 -->
            <signatureList
              @openAddSignature="openAddSignature"
              @addSignaToPdf="addSignaToPdf"
              @DragStartFn="DragStartFn"
              v-if="signatureVisible"
            />
          </template>
          <img
            style="width: 20px; height: 20px"
            src="@/assets/svg/documentTool/actionMenu/pdf_edit_signature.svg"
            alt=""
          />
          <span>签名</span>
        </a-popover>
      </div>

      <!-- 下载 -->
      <div class="item-box" @click="downPdf">
        <div class="item-box-pop">
          <img
            style="width: 20px; height: 20px"
            src="@/assets/svg/down.svg"
            alt=""
          />
          <span>下载</span>
        </div>
      </div>

      <!-- 保存 -->
      <div class="item-box" @click="savePdf">
        <div class="item-box-pop">
          <img
            style="width: 20px; height: 20px"
            src="@/assets/svg/save.svg"
            alt=""
          />
          <span>保存</span>
        </div>
      </div>

      <!-- 关闭 -->
      <div class="item-box" @click="closePdfEdit">
        <div class="item-box-pop">
          <img
            style="width: 20px; height: 20px"
            src="@/assets/svg/close.svg"
            alt=""
          />
          <span>关闭</span>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="box" id="iframe">
        <!-- 遮罩层 -->
        <div v-if="showMask" class="mask"></div>

        <a-spin :spinning="spinning" style="height: 100%">
          <!-- 加载PDF -->
          <iframe
            :src="pdfurl"
            ref="pdfIframe"
            class="iframe"
            id="pdf-iframe"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </a-spin>
      </div>
    </div>

    <!-- 添加修改水印参数实时预览水印效果弹窗 -->
    <addWatermarkModal
      v-if="visibleWatermark"
      :watermarkListLength="watermarkListLength"
      @confirmAddWatermark="confirmAddWatermark"
      @closeVisibleWatermark="closeVisibleWatermark"
      :visibleWatermark="visibleWatermark"
      :editData="editData"
    />

    <!-- 添加PDF签名弹窗 -->
    <addSignatureModal
      :signatureListLength="signatureListLength"
      @confirmAddSignature="confirmAddSignature"
      @closeVisibleAddSignatureModal="closeVisibleAddSignatureModal"
      v-if="visibleAddSignatureModal"
      :visibleAddSignatureModal="visibleAddSignatureModal"
    />

    <a-modal
      title="关闭文档"
      :footer="null"
      :visible="closePdfEditPage"
      :closable="false"
    >
      <div class="closePdf-content">
        未保存的编辑，关闭将会丢失编辑内容, 要继续吗？
      </div>
      <div class="closePdf-footer">
        <a-button type="primary" style="margin-right: 15px" @click="savePdf"
          >保存并退出</a-button
        >
        <a-button style="margin-right: 15px" @click="justOut">仅退出</a-button>
        <a-button style="margin-right: 15px" @click="closePdfEditPage = false"
          >取消</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import addWatermarkModal from "./components/addWatermarkModal/index.vue";
import addSignatureModal from "./components/addSignatureModal/index.vue";
import watermarkList from "./components/watermarkList/index.vue";
import signatureList from "./components/signatureList/index.vue";
import { PDFDocument } from "pdf-lib";
export default {
  name: "PdfEdit",
  data() {
    return {
      ob: null,
      id: "",
      userFileName: "",
      showMask: false,
      closePdfEditPage: false,
      imageDataBase64: "",
      waterParams: {},
      pdfHasWaterFlag: false, // pdf页面是否有水印，影响下载和保存--以及滚动懒加载更多pdf页时的水印
      signatureListLength: 0,
      watermarkListLength: 0,
      materialParams: "",
      signatureVisible: false, // PDF签名列表弹窗
      visibleAddSignatureModal: false, // 添加PDF签名弹窗,
      visible: false,
      spinning: false,
      visibleWatermark: false,
      pdfurl: "",
    };
  },
  components: {
    watermarkList,
    signatureList,
    addWatermarkModal,
    addSignatureModal,
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
  },
  async mounted() {
    this.id = await new URLSearchParams(window.location.search).get("id");
    this.userFileName = await decodeURIComponent(
      new URLSearchParams(window.location.search).get("userFileName")
    );
    console.log("this.id", this.id);
    console.log("this.userFileName", this.userFileName);
    // 获取pdf文件路径
    this.getPdfUrl();
    this.$nextTick(() => {
      this.initOverFlowYStyle();
    });

    this.$nextTick(() => {
      const iframe = this.$refs.pdfIframe;
      iframe.addEventListener("load", () => {
        // 绑定事件的逻辑
        setTimeout(() => {
          document
            .getElementById("pdf-iframe")
            .contentWindow.PDFViewerApplication.eventBus.on(
              "pagerendered",
              ({ source, cssTransform, pageNumber }) => {
                console.log("返回后为啥没了", {
                  source,
                  cssTransform,
                  pageNumber,
                });
                // 如果有水印，每次都调用一下水印生成
                if (this.pdfHasWaterFlag) {
                  // 第二个参数false 表示不保存模板，表存目标在子组件传参时已经处理调用接口。这里只处理PDF.js自带的懒加载 冲掉了水印的问题
                  this.setW(this.waterParams, false, pageNumber);
                }
              }
            );
        }, 1000);
      });
    });
  },
  beforeDestroy() {
    // 离开页面时，删除所有水印
    // this.deleteWater();
  },
  destroyed() {},
  created() {},
  methods: {
    DragStartFn() {
      this.showMask = true;
      setTimeout(() => {
        this.showMask = false;
      }, 3000);
    },

    // 删除页面水印
    deleteWater() {
      // 如果页面不存在水印，则不执行任何操作
      if (!this.pdfHasWaterFlag) {
        return;
      }
      this.pdfHasWaterFlag = false;
      const pages = document
        .getElementById("pdf-iframe")
        .contentWindow.document.querySelectorAll(".page");
      pages &&
        pages.forEach((page) => {
          const textLayerList = page.querySelectorAll(".textLayer");
          textLayerList &&
            textLayerList.length &&
            textLayerList.forEach(function (targetDom) {
              targetDom.style.background = `url('')`;
            });
        });
    },
    // 下载pdf文件
    async downPdf() {
      this.getPdfData("down");
    },
    // Blob触发文件下载方法
    downFilebyBlob(blob) {
      // 下载这个文件
      // 创建一个隐藏的 <a> 标签
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${this.userFileName}_PDF处理.pdf`); // 设置下载文件的名称
      // 兼容性问题：有些浏览器可能需要将链接添加到DOM中才能触发点击
      document.body.appendChild(link);
      link.click();
      // 然后移除这个链接（可选）
      document.body.removeChild(link);
      // 释放 URL 对象（可选，但推荐在不再需要时释放）
      URL.revokeObjectURL(link.href);
    },

    // 设置水印--pdf页面上，此时还未写入pdf
    async setW(val, bol, pageNumber) {

      this.waterParams = val; // 赋值保存一下，方便滚动懒加载pdf时传参调用

      const params = {
        ...val,
        opacity: val.opacity / 100,
        isSaveTemplate: bol,
        userFileId: this.id,
      };

      // 获取生成的canvas水印base64图
      this.getCanvasBase64(params);

      console.log("水印失效的pageNumber是多少", pageNumber);

      const pagesLength = document
        .getElementById("pdf-iframe")
        .contentWindow.document.querySelectorAll(".page");
      console.log("pagesLength.length", pagesLength.length);


      if (Number(pageNumber) == 1 || Number(pageNumber) == pagesLength.length) {
          // 监听滚动条吧还是
        let  pageHeight = document
        .getElementById("pdf-iframe")
        .contentWindow.document.querySelectorAll(".page")[0].offsetHeight;
        console.log('pageHeight',pageHeight);
        // 监听page的滚动条
        let targetDom= document
        .getElementById("pdf-iframe")
        .contentWindow.document.getElementById('viewerContainer')   
        
        let _that=this
        targetDom.addEventListener('scroll', function() {  
            // 获取滚动条距离顶部的距离  
            let scrollTop = targetDom.scrollTop;  
            // 获取可滚动元素的总高度  
            let scrollHeight = targetDom.scrollHeight;  
            // 获取可滚动元素的可视高度  
            let clientHeight = targetDom.clientHeight;  
          

            // 滚动到距离底部500px  
            if (scrollTop + clientHeight >= scrollHeight - 500) {  
                // 调用你的函数，比如scrollToBottomFunction  
                 _that.setTempWaterToPdf();
            }  
  
            // 滚动到距离顶部500px  
            if (scrollTop <= 500) {  
                // 调用你的函数，比如scrollToTopFunction  
                _that.setTempWaterToPdf();
            }  
        });  
        

      }
      this.setTempWaterToPdf();
    },



    // 生成临时水印在pdf页面上
    setTempWaterToPdf() {
      let _that = this;
      const pages = document
        .getElementById("pdf-iframe")
        .contentWindow.document.querySelectorAll(".page");
      pages.forEach((page) => {
        // const textLayerList = page.querySelectorAll(".textLayer");
        const textLayer = page.querySelector(".textLayer");
        let textDiv = textLayer ? textLayer : null;
        if (textDiv) {
          textDiv.style.background = `url(${_that.imageDataBase64})`;
          textDiv.style.backgroundRepeat = "repeat";
          textDiv.style.backgroundSize = "100% 100%"; // 确保背景图填充整个textLayer
        }
      });
    },

    // 获取生成水印的cavans的base64图的方法
    getCanvasBase64(params) {
      const page = document
        .getElementById("pdf-iframe")
        .contentWindow.document.querySelectorAll(".page")[0];

      let adjustedOpacity;
      if (params.opacity < 0.5) {
        adjustedOpacity = params.opacity + Math.abs(params.opacity - 0.5) * 0.4;
      } else {
        adjustedOpacity = params.opacity - Math.abs(params.opacity - 0.5) * 0.4;
      }
      adjustedOpacity = Math.max(0, Math.min(1, adjustedOpacity));
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = page.offsetWidth;
      canvas.height = page.offsetHeight;
      ctx.font = `${params.fontSize}px ${params.fontFamily}`;
      ctx.fillStyle = `rgba(20, 20, 20, ${adjustedOpacity})`;
      // ctx.fillStyle = `rgb(0, 0, 0)`;
      ctx.globalAlpha = adjustedOpacity;
      let x = 0;
      let y = 0;
      while (y < canvas.height) {
        x = 0;
        while (x < canvas.width) {
          ctx.save();
          ctx.translate(x, y);
          // ctx.rotate((params.rotation * Math.PI) / 180);
          ctx.rotate(-params.rotation * (Math.PI / 180));
          ctx.fillText(params.watermarkText, 0, 0);
          ctx.restore();
          x += params.widthSpacer + ctx.measureText(params.watermarkText).width;
        }
        // y += params.heightSpacer + params.fontSize;
        y += params.heightSpacer + params.fontSize * (params.fontSize / 40);
        // y += params.heightSpacer + ctx.measureText(params.watermarkText).height;
      }
      const imageData = canvas.toDataURL();
      this.imageDataBase64 = imageData;
    },

    // 仅退出
    justOut() {
      this.$router.push({
        name: "star",
      });
    },

    // 获取PDF数据，包含把水印数据写入到pdf文件中的方法,调用时传入保存还是下载
    async getPdfData(menu) {
      let _that = this;
      const data = await document
        .querySelector("#pdf-iframe")
        .contentWindow.PDFViewerApplication.pdfDocument.saveDocument();
      const blob = new Blob([data], { type: "application/pdf" });
      if (!this.pdfHasWaterFlag) {
        if (menu == "down") {
          this.downFilebyBlob(blob);
        } else {
          this.uploadPdf(blob);
        }
        return;
      }

      // 使用FileReader将Blob转换为ArrayBuffer
      const reader = new FileReader();
      reader.onload = async function (event) {
        let existingPdfBytes = event.target.result; // 这就是ArrayBuffer
        console.log("existingPdfBytes", existingPdfBytes);
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages_ = pdfDoc.getPages();
        // 疑问：imageDataBase64为图片base64数据，单独在浏览器中打开这个base64的水印图片，会小一些，一横排能放七八个水印文本左右。下方的插入pdf每一页之后，生成的pdf文件流再预览打开，只能放下四个左右，感觉文字也大了一些
        console.log("imageDataBase64---", _that.imageDataBase64);
        const imagePDF = await pdfDoc.embedPng(_that.imageDataBase64);

        const size_ = pages_[0].getSize();
        const pageWidth = size_.width;
        const pageHeight = size_.height;

        for (let i = 0; i < pages_.length; i++) {
          pages_[i].drawImage(imagePDF, {
            x: 0, // 图片左上角的x坐标
            y: 0, // 图片左上角的y坐标
            width: pageWidth, // 图片的宽度
            height: pageHeight, // 图片的高度
          });
        }
        // 序列化为字节
        const pdfBytes = await pdfDoc.save();
        const newBlob = new Blob([pdfBytes], {
          type: "application/pdf;charset=utf-8",
        });

        if (menu == "down") {
          _that.downFilebyBlob(newBlob);
        } else {
          _that.uploadPdf(newBlob);
        }
      };
      reader.readAsArrayBuffer(blob);
    },
    // 上传pdf文件给后端
    async uploadPdf(Blob) {
      this.spinning = true;
      let formData = new FormData();
      formData.append("file", Blob, "filename.pdf");
      formData.append("originalUserFileId", this.id);
      formData.append("operationType", "WATERMARK");

      try {
        const result = await this.$apis.uploadPdfHttp(
          formData,
          (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("输出上传进度百分比", percentCompleted); //
            if (percentCompleted == 100) {
              setTimeout(() => {
                this.$message.success("保存成功");
              }, 500);

              setTimeout(() => {
                this.$router.push({
                  name: "star",
                });
              }, 1000);
            }
          }
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        setTimeout(() => {
          this.spinning = false;
        }, 500);
      }
    },

    // 添加图片
    addImage() {
      // console.log(document.querySelector("#pdf-iframe").contentWindow.PDFViewerApplication.pdfViewer.annotationEditorMode);

      // 开启图片批注模式
      document.querySelector(
        "#pdf-iframe"
      ).contentWindow.PDFViewerApplication.pdfViewer.annotationEditorMode = {
        mode: 13,
      };
      setTimeout(() => {
        document
          .querySelector("#pdf-iframe")
          .contentWindow.PDFViewerApplication.pdfViewer._layerProperties.annotationEditorUIManager.currentLayer.createAndAddNewEditor(
            {
              offsetX: 400,
              offsetY: 300,
            },
            true
          );
      }, 10);
    },

    // 保存PDF
    async savePdf() {
      this.closePdfEditPage = false;
      this.getPdfData("save");
    },

    base64toFile(base64Data, filename) {
      var arr = base64Data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    // 签名开始拖拽了
    addSignaToPdf(val) {
      this.materialParams = val;
      // 开启图片批注模式
      document.querySelector(
        "#pdf-iframe"
      ).contentWindow.PDFViewerApplication.pdfViewer.annotationEditorMode = {
        mode: 13,
      };
      setTimeout(() => {
        // 第一步，获取pdf页码此时的宽度
        // 第二步  获取图片本身的宽度
        // 第三步  计算偏移量,offsetX的值, 目的是要保持图片在正中间,那么应该是
        document
          .querySelector("#pdf-iframe")
          .contentWindow.PDFViewerApplication.pdfViewer._layerProperties.annotationEditorUIManager.currentLayer.createAndAddNewEditor(
            {
              offsetX: 500,
              offsetY: 300,
            },
            true,
            {
              bitmapFile: this.base64toFile(this.materialParams),
            }
          );
      }, 10);
    },
    DragEnd(x, y, imgSrc) {
      console.log("x,y,imgSrc", x, y, imgSrc);
    },
    initOverFlowYStyle() {
      const el = document.getElementById("micro-layout-container");
      el && (el.style.overflowY = "hidden"); // 设置为hidden来隐藏垂直滚动条
    },

    // 打开PDF签名弹窗
    openAddSignature(val) {
      this.signatureListLength = val;
      console.log("传递的签名类别数量", this.signatureListLength);
      this.visibleAddSignatureModal = true;
    },

    // 关闭添加PDF签名弹窗
    closeVisibleAddSignatureModal() {
      this.visibleAddSignatureModal = false;
    },

    // 从签名弹框确认添加PDF签名
    confirmAddSignature(val) {
      console.log("从签名弹框确认添加PDF签名", val);
    },
    // 初始加载pdf
    getPdfUrl() {
      this.spinning = true;
      let fileUrl = `${process.env.VUE_APP_PDF_EDIT}/file/resource?userFileId=${this.id}&token=${this.token}`;
      this.pdfurl = `/pdfjs/web/viewer.html?file=${encodeURIComponent(
        fileUrl
      )}`;
      setTimeout(() => {
        this.spinning = false;
      }, 1000);

      // 本地环境--后端真实pdf
      // let fileUrl = `http://172.30.15.58:9006/file/resource?userFileId=571360751348903936&token=${this.token}`;
      // this.pdfurl = `http://localhost:8080/pdfjs/web/viewer.html?file=${encodeURIComponent(
      //   fileUrl
      // )}`;

      // 测试环境
      // let fileUrl = `http://192.168.80.8/star-doc/file/resource?userFileId=571360751348903936&token=${this.token}`;
      // this.pdfurl = `http://192.168.80.8:86/star-pan-web/pdfjs/web/viewer.html?file=${encodeURIComponent(
      //   fileUrl
      // )}`;
    },

    // 确认生成水印
    async confirmAddWatermark(val, bol, type) {
      this.pdfHasWaterFlag = true;
      this.setW(val, bol);

      // return;

      this.visibleWatermark = false;
      // let params = {
      //   ...val,
      //   opacity: val.opacity / 100,
      //   alphabet: "chinese",
      //   materialType: "WATERMARK",
      //   isSaveTemplate: bol,
      // };

      // this.addWatermark();
      console.log("type是啥", type);
      if (bol) {
        if (type && type == "edit") {
          // 编辑
          console.log("999999999999999999999val", val);
          let query = {
            materialType: "WATERMARK",
            id: val.editId,
            materialParams: JSON.stringify({
              ...val,
              opacity: val.opacity / 100,
            }),
          };
          try {
            this.spinning = true;
            const res = await this.$apis.edidSignatureHttp(query);
            console.log("-----res------", res);
            this.$message.success("更新成功");
          } catch (error) {
            console.log("error", error);
          } finally {
            this.spinning = false;
          }
        } else {
          // 新增

          let params = {
            materialType: "WATERMARK",
            materialParams: JSON.stringify({
              ...val,
              opacity: val.opacity / 100,
            }),
          };
          try {
            this.spinning = true;
            const res = await this.$apis.addSignatureHttp(params);
            console.log("-----res------", res);
            this.$message.success("保存成功");
          } catch (error) {
            console.log("error", error);
          } finally {
            this.spinning = false;
          }
        }
      }

      // const data=  this.$apis.getPdfWatermarkTempFileHttp({
      //   localPath:res.data
      // })
      // console.log('测试水印data',data);
      // this.pdfurl = `http://localhost:8080/pdfjs/web/viewer.html?file=${( data )}`;

      // let fileUrl = `http://localhost:3000/star-doc/document/watermark/temporary/file/resource?localPath=${encodeURIComponent(
      //   res.data
      // )}&token=${this.token}`;
      // // let fileUrl = `http://172.30.15.58:9006/document/watermark/temporary/file/resource?localPath=${res.data}&token=${this.token}`;

      // this.pdfurl = `/pdfjs/web/viewer.html?file=${encodeURIComponent(
      //   fileUrl
      // )}`;
    },
    // 关闭水印弹框工具
    closeVisibleWatermark() {
      this.visibleWatermark = false;
    },
    // 打开添加水印弹框
    openAddWatermark(val, item) {
      this.watermarkListLength = val;
      this.visible = false;
      this.visibleWatermark = true;
      // 存在第二个参数item，则表示是编辑模式
      if (item && item.fontSize) {
        this.editData = item;
      } else {
        // 添加水印，置空
        this.editData = {};
      }
    },
    addWatermark() {
      this.spinning = false;
      // let fileUrl =
      //   "http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf";
      let fileUrl = `http://172.30.15.58:9006/file/resource?userFileId=${this.id}&token=${this.token}`;
      this.canvasWatermark(fileUrl);
    },
    async canvasWatermark(url) {
      // const existingPdfBytes = await fetch(url).then((res) =>
      //   res.arrayBuffer()
      // );

      let existingPdfBytes;
      const data = await document
        .querySelector("#pdf-iframe")
        .contentWindow.PDFViewerApplication.pdfDocument.saveDocument();
      const blob = new Blob([data], { type: "application/pdf" });
      // 使用FileReader将Blob转换为ArrayBuffer
      const reader = new FileReader();
      reader.onload = function (event) {
        existingPdfBytes = event.target.result; // 这就是ArrayBuffer
        console.log("existingPdfBytes", existingPdfBytes);
        // 在这里处理ArrayBuffer...
      };
      reader.readAsArrayBuffer(blob);

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // 旋转角度大小
      const rotateAngle = Math.PI / 6;
      // const rotateAngle = Math.PI / 6;

      // labels是要显示的水印文字，垂直排列
      const labels = [];
      labels.push("旋转小火锅");

      const pages = pdfDoc.getPages();
      const size = pages[0].getSize();

      const pageWidth = size.width;
      const pageHeight = size.height;

      // 生成 canvas 文字图片
      const canvas = document.createElement("canvas");
      let canvasWidth = (canvas.width = pageWidth);
      let canvasHeight = (canvas.height = pageHeight);

      const context = canvas.getContext("2d");
      context.font = "24px Arial";
      context.shadowColor = "rgba(187, 187, 187, .2)";

      // 在绕画布逆方向旋转30度
      context.rotate(-rotateAngle);

      // 获取文本的最大长度
      const textWidth = Math.max(
        ...labels.map((item) => context.measureText(item).width)
      );

      const lineHeight = 20;
      const fontHeight = 40;
      let positionY = 0;
      let i = 0;
      while (positionY <= pageHeight) {
        positionY = positionY + lineHeight * 5;
        i++;
      }
      canvasWidth += Math.sin(rotateAngle) * (positionY + i * fontHeight); // 给canvas加上画布向左偏移的最大距离
      canvasHeight = 2 * canvasHeight;
      for (
        positionY = 0, i = 0;
        positionY <= canvasHeight;
        positionY = positionY + lineHeight * 5
      ) {
        // 进行画布偏移是为了让画布旋转之后水印能够左对齐;
        context.translate(
          -(Math.sin(rotateAngle) * (positionY + i * fontHeight)) -
            (i % 2) * 50,
          0
        );
        for (
          let positionX = 0;
          positionX < canvasWidth;
          positionX += 3 * textWidth - 100
        ) {
          let spacing = 0;
          labels.forEach((item) => {
            context.fillText(item, positionX, positionY + spacing);
            context.fillStyle = "rgba(187, 187, 187, .8)"; // 字体颜色
            spacing = spacing + lineHeight;
          });
        }
        context.translate(
          Math.sin(rotateAngle) * (positionY + i * fontHeight),
          0
        );
        context.restore();
        i++;
      }

      // 图片的base64编码路径
      const png = canvas.toDataURL("img/png");
      // 获取图片
      const imagePDF = await pdfDoc.embedPng(png);
      for (let i = 0; i < pages.length; i++) {
        pages[i].drawImage(imagePDF);
      }
      // 序列化为字节
      const pdfBytes = await pdfDoc.save();
      // 预览
      this.preView(pdfBytes);
      // ----------- 优化 --------------------
    },

    preView(stream) {
      const URL = window.URL;
      const href = URL.createObjectURL(
        new Blob([stream], { type: "application/pdf;charset=utf-8" })
      );
      console.log("href", href);
      this.pdfurl = `/pdfjs/web/viewer.html?file=${href}`;
    },

    // 跳转到首页
    closePdfEdit() {
      this.closePdfEditPage = true;
      return;
      let _that = this;
      this.$confirm({
        title: "确定关闭pdf编辑吗?",
        content: (h) => (
          <div style="color:red;">未保存的编辑，关闭将会丢失编辑内容</div>
        ),
        onOk() {
          _that.$router.push({
            name: "star",
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pdf-edit-page {
  width: 100%;
  height: 100%;

  // 自定义菜单栏
  .custom-menu-btn {
    position: absolute;
    height: 60px;
    right: 40px;
    top: 10px;
    z-index: 11 !important;
    display: flex;
    align-items: center;
    .item-box {
      user-select: none;
      margin-right: 2px;
      padding: 2px;
      width: 50px !important;
      height: 50px !important;
      display: flex;
      justify-content: center !important;
      flex-direction: column !important;
      align-items: center !important;
      .item-box-pop {
        padding: 2px;
        cursor: pointer;
        width: 50px !important;
        height: 50px !important;
        display: flex;
        justify-content: space-between !important;
        flex-direction: column !important;
        align-items: center !important;
        font-size: 12px !important;
        color: #000 !important;
      }
      &:hover {
        background-color: #dddedf;
      }
    }
  }

  // 自定义最右侧退出按钮
  .custom-menu-btn-close {
    position: absolute;
    height: 60px;
    right: 25px;
    top: 10px;
    z-index: 2000 !important;
    display: flex;
    align-items: center;
    .item-box {
      padding-top: 1px;
      width: 28px !important;
      height: 28px !important;
      display: flex;
      justify-content: center;
      align-items: center;
 
      &:hover {
        background-color: #dddedf;
      }
    }
  }

  .header {
    width: 100%;
    height: 64px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    .box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .mask {
        z-index: 11 !important;
        position: absolute; /* 绝对定位相对于最近的已定位祖先元素（在这里是 .box） */
        top: 60px;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.1); /* 半透明的黑色背景 */
        background-color: transparent; /* 透明背景 */
        pointer-events: auto !important; /* 允许遮罩层接收鼠标事件 */
      }
    }
    .iframe {
      position: relative;
      z-index: 10;
    }
  }
}
</style>

<style>
.ant-spin-container {
  height: 100% !important;
}

.closePdf-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
}
.closePdf-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
